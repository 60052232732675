@import url('~material-icons-font/material-icons-font.css');

html {
  background-color: #eeeeee;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

input {
  font: inherit;
}

.material-icons {
  font-size: 1.5rem;
}
