@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');

html {
  font-family: Poppins, Roboto, 'Segoe UI', sans-serif;
}

.ReactCollapse--collapse {
  transition: height 100ms;
}

.ml-section {
  background-color: #ffffff;
  box-shadow:
    0 0.1rem 0.3rem -0.1rem rgba(0, 0, 0, 0.24),
    0 0.1rem 0.1rem -0.1rem rgba(0, 0, 0, 0.12);
  border-radius: 0.2rem;
  margin: 0.5rem;
}

.ml-card-header {
  background-color: #edf3f6;
  border-radius: 0.2rem 0.2rem 0 0;
}

.ml-card-header-content {
  font-weight: 400;
  color: #192a3e;
  padding: 0.5rem 1rem;
}

.ml-card-content {
  padding: 0.5rem;
}

.ml-form {
  display: block;
  margin: 0;
  padding: 0;
}

.ml-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ml-list-item {
  display: flex;
  align-items: center;
  border-top: 1px solid #eeeeee;
}

.ml-list-item:first-of-type {
  border-top: 0;
}

.ml-list-item-icon {
  flex: 0 0 3rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0000008a;
}

.ml-list-item-content {
  flex: 1 1 auto;
  padding: 0.5rem;
}

.ml-list-item-primary {
  font-weight: 400;
  /* color: #364556; */
}

.ml-list-item-secondary {
  font-weight: 220;
  /* color: #6a707e; */
}

.ml-field-container {
  margin: 0;
  border: 0;
  padding: 0.5rem;
  background: white;
}

.ml-field-label {
  font-weight: 400;
}

.ml-input {
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 0.5rem;
  border: 1px solid #839ab4;
  background-color: #ffffff;
  border-radius: 0.2rem;
  width: 100%;
  box-sizing: border-box;
  font: inherit;
}

.ml-input[readonly] {
  border: 1px solid #b5babe;
  background-color: #e5ebf1;
}

.ml-input:disabled {
  border: 1px solid #b5babe;
  background-color: #e5ebf1;
}

.ml-dialogheader-container {
  box-sizing: border-box;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;

  background-color: #ffffff;
  border-bottom: 4px solid #009a44;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
}

.ml-dialogheader-backward {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.ml-dialogheader-center {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.ml-dialogheader-forward {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.ml-dialogheader-text {
  font-weight: 300;
  font-size: 1.5rem;
  letter-spacing: -0.05em;
  /* color: #6a707e; */
}

.ml-toolbar-container {
  box-sizing: border-box;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  background-color: #009a44;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
}

.ml-toolbar-backward {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.ml-toolbar-center {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.ml-toolbar-forward {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.ml-button {
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0.5rem;
  border-radius: 0.2rem;
  height: 3rem;
  cursor: pointer;

  background-color: #ffffff00;
  background-color: var(--color);
  color: #ffffff;

  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.ml-button:hover {
  filter: brightness(80%);
}

.ml-button:active {
  filter: brightness(60%);
}

.ml-icon-button {
  box-sizing: border-box;
  border: 1px solid #6a707e;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;

  background-color: #ffffff;
  border-color: var(--color);
  color: var(--color);
}

.ml-icon-button:hover {
  filter: brightness(80%);
}

.ml-icon-button:active {
  filter: brightness(60%);
}

.ml-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ml-caption-container {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  padding: 0.5rem;
  align-items: center;
}

.ml-caption-icon {
  color: #6a707e;
}

.ml-caption-label {
  color: #6a707e;
}

.ml-alert-container {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 1.5rem;
  align-items: center;
  background-color: #e6980c;
}

.ml-alert-icon {
  display: flex;
  color: #ffffff;
  align-self: flex-start;
}

.ml-alert-label {
  color: #ffffff;
  overflow: auto;
}

.ml-card-content .ml-alert-container {
  margin: 0 -0.5rem;
}

.ml-counter-container {
  display: flex;
  align-items: center;
}

.ml-counter-content {
  flex: 1 1 auto;
}

.ml-counter-count {
  flex: 0 0 auto;
  background-color: #6a707e;
  color: #ffffff;
  border-radius: 0.5rem;

  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ml-count-success {
  background-color: #009a44;
}

.ml-count-fail {
  background-color: #d68100;
  margin: 0 0.5rem;
}

.ml-counterbadge-container {
  display: grid;
}

.ml-counterbadge-content {
  grid-area: 1/1;
}

.ml-counterbadge-count {
  grid-area: 1/1;
  justify-self: flex-end;
  align-self: flex-end;

  background-color: #009a44;
  font-size: 0.7rem;
  color: #ffffff;
  border-radius: 0.6rem;
  width: 1.2rem;
  height: 1.2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0.2rem, -0.2rem);
}

.ml-required {
  color: #9a0000;
  font-weight: 500;
  line-height: 0;
  vertical-align: middle;
  font-size: 1.5rem;
}

.ml-error-flash {
  animation: error-flash 3s;
}

@keyframes error-flash {
  50% {
    background-color: #e79c2bbe;
  }
}

.ml-status-text {
  font-weight: 600;
  vertical-align: middle;
}

.ml-status-text--good {
  color: #009a44;
}

.ml-status-text--bad {
  color: #d68100;
}

.ml-flash-alert-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  font-size: 1.3rem;
}

.ml-flash-alert-warning {
  background-color: #e6980c;
  border-radius: 6px;
  color: white;
  margin: 10px;
  position: sticky;
  top: 0;
  right: 0;
  text-align: center;
  padding: 0.4em;
}

.ml-flash-alert-success {
  background-color: #32cd32;
  border-radius: 6px;
  color: white;
  margin: 10px;
  position: sticky;
  top: 0;
  right: 0;
  text-align: center;
}

.ml-tag {
  border-radius: 1em;
  border: 0.1em solid #6a707e;
  padding: 0.2em 1em;
  background: white;
}

.ml-search {
  margin-bottom: 0.5rem;
}

.ml-combobox-list {
  display: none;
  border: 1px solid #839ab4;
  background-color: #ffffff;
  border-radius: 0.2rem;
  max-height: 30rem;
  overflow-y: scroll;
}

.ml-environment {
  color: rgba(0, 0, 0, 0.54);
  border-right: 1px solid rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  display: block;
  line-height: 1em;
  padding-right: 0.8rem;
  margin-right: 0.8rem;
}

.select-jid-filterGroup {
  margin-top: 0.9rem;
  padding-right: 0.1rem;
  background-color: #ebeff2;
  width: auto;
  border-radius: 4px;
}

.select-jid-filterItems {
  margin-bottom: 0.4rem;
  margin-top: 0.9rem;
  height: 1.4rem;
  font-size: 0.875rem;
}

/* ******* COMMON *******/

.gh-flexrow {
  display: flex;
  flex-direction: row;
}

.gh-flexColumn {
  display: flex;
  flex-direction: row;
}

/* ******* MY TASKS PAGE - WORK ITEM CARD *******/

.gh-workItem-card {
  border-bottom: 1px #ccd1d4 solid;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.gh-workItem-card-header {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 400;
  justify-content: space-between;
  padding-bottom: 0.25rem;
  gap: 0.5em;
}

.gh-workItem-card-content div {
  font-weight: lighter;
  line-height: initial;
  white-space: nowrap;
}

/* ******* MY TASKS PAGE - TASKS ITEM CARD *******/

.gh-taskItem-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.gh-taskItem-card-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 500;
  flex-grow: 1;
  color: black;
}

.gh-taskItem-card-content {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 300;
  color: black;
}

.gh-taskDetails-page-subtitle {
  padding: 0.4rem 0 0.32rem 0;
  background-color: white;
  text-align: center;
  box-shadow:
    0 0.1rem 0.3rem -0.1rem rgba(0, 0, 0, 0.24),
    0 0.1rem 0.1rem -0.1rem rgba(0, 0, 0, 0.12);
  font-weight: lighter;
}

.gh-dialog-content-text {
  color: #000;
  font-size: 18px;
  font-weight: lighter;
  text-align: center;
  background-color: white;
  padding: 1.5rem 1rem;
  box-shadow:
    0 0.1rem 0.3rem -0.1rem rgba(0, 0, 0, 0.24),
    0 0.1rem 0.1rem -0.1rem rgba(0, 0, 0, 0.12);
}

.gh-line-clamp-2 {
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ******* MY TASKS PAGE - FILTER DIALOG *******/

.gh-workItem-filter-date-jump-buttons button {
  background-color: #f1f1f1;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 0.1rem 0.3rem -0.1rem rgba(0, 0, 0, 0.24);
  white-space: nowrap;
}

.gh-workItem-filter-date-jump-buttons button:hover {
  background-color: #eee;
}

/* ******* JOB PAGE - JOB DETAILS *******/

.gh-jobdetail-card {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  padding: 1rem;
  line-height: 1.3rem;
  color: rgb(17, 17, 17);
  border-top: 1px #ccd1d4 solid;
}

.gh-jobdetail-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.25rem;
}

.gh-jobdetail-row:last-child {
  padding-bottom: 0rem;
}

/* ******* JOB PAGE - PHASE SECTION *******/

.gh-phase-card {
  color: #000000f0;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5rem;
  padding: 0.5rem 1rem;
  align-items: baseline;
  justify-content: space-between;
  border-top: 1px #ccd1d4 solid;
}

.gh-phase-card-column-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 14px;
}

.gh-phase-card-left-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

/* ******* FORMS  *******/

.gh-form-field-title {
  color: black;
  font-weight: 350;
  line-height: 1.2rem;
}

.gh-form-field-title-disabled {
  color: #e0e0e0;
  font-weight: 350;
  line-height: 1.2rem;
}

.gh-dialog-header-title {
  font-size: 24px;
  font-weight: 350;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.gh-field-quantity {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
}

.gh-field-quantity-tekst {
  align-items: center;
  display: flex;
  flex-grow: 2;
  padding: 6px;
}

.gh-section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: black;
}

/* ******* FORMS - TASK SECTION *******/

.gh-form-task-card {
  padding: 1rem;
}

.gh-form-task-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  color: black;
}

/* ******* FORMS - SELECTED DETAILS SECTION *******/

.gh-selected-form-header {
  background-color: #e0e0e0;
  font-weight: 350;
  color: black;
  padding: 0.5rem 1rem;
}

.gh-selected-form-header-empty {
  background-color: #e0e0e0;
  color: #00000060;
  font-weight: 350;
  padding: 0.2rem 1rem;
}

.gh-selected-form-details {
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-weight: 300;
}

.gh-selected-key {
  display: flex;
  min-width: 80px;
}

.gh-selected-key-empty {
  color: #00000060;
  display: flex;
  min-width: 85px;
}

/* Override Mui DatePickers CSS */
.MuiPickersDay-current {
  color: #009a44 !important;
}

.MuiPickersDay-daySelected {
  background-color: #009a44 !important;
  color: #ffffff !important;
}

.MuiFormLabel-root {
  color: #000000f0 !important;
  font-weight: 350 !important;
  line-height: 1.2rem !important;
}